import { Card, Button } from '@shopify/polaris';
import EmailEditor, { EditorRef } from 'react-email-editor';
import { StyledTemplateEditor } from './styled';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyGetTemplateDetailQuery, useUpdateTemplateMutation } from '@/redux/api/api.caller';
import { useDispatch, useSelector } from 'react-redux';
import { emailTemplatesTabSelector, orderStatusSelector } from '@/redux/features/email.slice';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { ErrorBanner } from '@/components';

const TemplateEditor = () => {
  const [apiError, setApiError] = useState<string>('');
  const emailEditorRef = useRef<EditorRef>(null);
  const dispatch = useDispatch();
  const orderStatusState = useSelector(orderStatusSelector);
  const [getTemplateDetail, { data }] = useLazyGetTemplateDetailQuery();
  const [updateTemplate, { isLoading }] = useUpdateTemplateMutation();
  const templatesTab = useSelector(emailTemplatesTabSelector);

  const handleLoadTemplate = useCallback(() => {
    getTemplateDetail({ trackingStatus: orderStatusState, receiver: templatesTab }).then(({ data }) => {
      if (data && data.data.parsedJsonTemplate && emailEditorRef.current && emailEditorRef.current.editor) {
        emailEditorRef.current.editor.loadDesign(JSON.parse(data.data.parsedJsonTemplate));
      }
    });
  }, [getTemplateDetail, orderStatusState, templatesTab]);

  const handleSaveTemplate = () => {
    if (emailEditorRef && emailEditorRef.current && emailEditorRef.current.editor) {
      emailEditorRef.current.editor.exportHtml(
        (data) => {
          const { design, html } = data;
          updateTemplate({
            status: orderStatusState,
            parsedHtmlTemplate: html,
            parsedJsonTemplate: JSON.stringify(design),
            receiver: templatesTab,
          }).then((data: any) => {
            if (data?.data?.state === 1) {
              setApiError('');
              dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
              return;
            }
            setApiError(getErrorFromAPI(data));
          });
        },
        { cleanup: true },
      );
    }
  };

  useEffect(() => {
    handleLoadTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusState, data]);

  return (
    <StyledTemplateEditor>
      <Card>
        <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
        <EmailEditor ref={emailEditorRef} onLoad={handleLoadTemplate} minHeight="80vh" />
        <div className="save-template-btn">
          <Button variant="primary" onClick={handleSaveTemplate} loading={isLoading}>
            Save template
          </Button>
        </div>
      </Card>
    </StyledTemplateEditor>
  );
};

export default TemplateEditor;
