import { IToast } from '@/types';

export const handleToastMutation = (res: any): IToast => {
  if ('data' in res && res?.data?.state === 1) {
    return {
      isOpen: true,
      content: res?.data?.msg || 'Save successfully',
      error: false,
    };
  }
  return {
    isOpen: false,
    content: 'Something happened',
  };
};

export const getErrorFromAPI = (res: any): string => {
  if ('data' in res && res.data.state !== 1) {
    return res?.data?.msg || 'Save failed';
  }
  return res?.error?.data?.message?.toString() || res?.data?.msg || 'Save failed';
};
