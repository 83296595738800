import Layout from '@/components/Layout';
import { AccountPlan, ProductRecommendType, ProgressBar, ShippingInfo } from '@/constants/enum';
import { hexToHSB } from '@/helpers/color';
import { useGetTrackingPageQuery, useUpdateTrackingPageMutation } from '@/redux/api/api.caller';
import trackingPageSlice, {
  languageSelector,
  tabSelectedSelector,
  trackingPageSelector,
} from '@/redux/features/trackingPage.slice';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import ControlBar from '@/components/ControlBar';
import { isLockFeature } from '@/helpers/feature';
import planSlice, { accountSelector } from '@/redux/features/plan.slice';
import { tabsConfigPage } from './config';
import { CustomPage, ErrorBanner } from '@/components';
import { LINK, TrackingPageTabs } from '@/constants';
import { ViewMinor } from '@shopify/polaris-icons';
import { tokenSelector } from '@/redux/features/auth.slice';
import { isUndefinedArray } from '@/helpers';

const TrackingPage = () => {
  const selectedTab = useSelector(tabSelectedSelector);
  const isLock = isLockFeature([AccountPlan.Starter]);
  const [updateTrackingPage, { isLoading }] = useUpdateTrackingPageMutation();
  const getTrackingPage = useGetTrackingPageQuery(undefined, {
    skip:
      [TrackingPageTabs.SetUp, TrackingPageTabs.Blacklist, TrackingPageTabs.Edd, TrackingPageTabs.Language].includes(
        selectedTab,
      ) ||
      (isLock && selectedTab === TrackingPageTabs.ProductRecommendation),
  });
  const { shop } = useSelector(tokenSelector);
  const language = useSelector(languageSelector);
  const dispatch = useDispatch();
  const tabSelected = useSelector(tabSelectedSelector);
  const isTabInTrackingPageTabs = Object.values(TrackingPageTabs).includes(tabSelected);
  const trackingPage = useSelector(trackingPageSelector);
  const {
    value: {
      lookAndFeel: {
        content: { shippingInfo, progressBar },
      },
    },
  } = trackingPage;
  const accountState = useSelector(accountSelector);
  const plan = useSelector(accountSelector);
  const [apiError, setApiError] = useState<string>('');

  const handleTabSelected = (id: Array<TrackingPageTabs>) => {
    dispatch(trackingPageSlice.actions.handleTabSelected(id[0]));
  };

  useEffect(() => {
    dispatch(trackingPageSlice.actions.handleTrackingPageIsloading(getTrackingPage.isLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrackingPage.isLoading]);

  useEffect(() => {
    if (Object.entries(trackingPage.fieldsChange).length > 0) {
      dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
    }
    if (Object.entries(language.fieldsChange).length > 0) {
      dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = getTrackingPage.data?.data;
    if (data) {
      const { lookAndFeel, configuration } = data;
      const trackingPageValue = {
        lookAndFeel: {
          chooseTheme: lookAndFeel.general.theme.toString(),
          lookupOption: {
            method: lookAndFeel.trackingForm.showMethods,
            requireEmail: lookAndFeel.trackingForm.requireEmail,
          },
          content: {
            shippingInfo: lookAndFeel.general.show.split(',') as Array<ShippingInfo>,
            format: {
              dateFormat: lookAndFeel.general.dateFormat,
              timeFormat: lookAndFeel.general.timeFormat,
            },
            progressBar: lookAndFeel.displayStatusBar.split(',') as Array<ProgressBar>,
          },
          style: {
            btnRadius: lookAndFeel.general.buttonBorder,
            colorPicker: {
              btnColor: hexToHSB(lookAndFeel.general.buttonColor),
              btnNameColor: hexToHSB(lookAndFeel.general.buttonTextColor),
              mainColor: hexToHSB(lookAndFeel.general.mainColor),
              textColor: hexToHSB(lookAndFeel.general.textColor),
            },
          },
        },
        configuration: {
          notInfoOrder: {
            isNoInfo: configuration.customMessage.trackUrl.isActive,
            isNotFound: configuration.customMessage.isReplaceMessage,
            message: configuration.customMessage.replaceMessage,
          },
          customStatus: {
            isCustomStatus: configuration.customStatus.enabled,
            activeAfter: configuration.customStatus.timeActive.toString(),
            status: configuration.customStatus.icon.toString(),
            statusName: configuration.customStatus.label,
          },
          productRecommendation: {
            enable: configuration.productRecommend.isActive,
            title: isLock && !plan.isOldPaidUser ? '' : configuration.productRecommend.title,
            type: isLock && !plan.isOldPaidUser ? ProductRecommendType.AllProduct : configuration.productRecommend.type,
            value:
              configuration.productRecommend.type === ProductRecommendType.productType
                ? configuration.productRecommend.productType
                : configuration.productRecommend.tags,
          },
          review: {
            settings: {
              isEnableReview: configuration.reviewQuestions.isActive,
              isDisabledDisplayReview: configuration.reviewQuestions.reviewSettings.isActive,
              displayReviewOption: configuration.reviewQuestions.reviewSettings.show,
            },
            question_1: {
              display: configuration.reviewQuestions.question_1.isDisplay,
              value: configuration.reviewQuestions.question_1.title,
              oneStarTitle: configuration.reviewQuestions.question_1.iconStatusBad?.toString(),
              fiveStarTitle: configuration.reviewQuestions.question_1.iconStatusGood.toString(),
              displayReasons: configuration.reviewQuestions.question_1.isDisplayReason,
              minReason: configuration.reviewQuestions.question_1.limitLabelRequired?.toString(),
              reasons: configuration.reviewQuestions.question_1.labelQuestion,
            },
            question_2: {
              display: configuration.reviewQuestions.question_2.isDisplay,
              value: configuration.reviewQuestions.question_2.title,
              oneStarTitle: configuration.reviewQuestions.question_2.iconStatusBad.toString(),
              fiveStarTitle: configuration.reviewQuestions.question_2.iconStatusGood.toString(),
            },
            question_3: {
              display: configuration.reviewQuestions.question_3.isDisplay,
              value: configuration.reviewQuestions.question_3.title,
              oneStarTitle: configuration.reviewQuestions.question_3.iconStatusBad.toString(),
              fiveStarTitle: configuration.reviewQuestions.question_3.iconStatusGood.toString(),
            },
            additionalComment: {
              display: configuration.reviewQuestions.additionalComment.isDisplay,
              value: configuration.reviewQuestions.additionalComment.title,
            },
            thankMessage: {
              display: configuration.reviewQuestions.thankMsg.isDisplay,
              value: configuration.reviewQuestions.thankMsg.title,
            },
          },
          footerLink: {
            ...configuration.footerLinks.policy,
            ...configuration.footerLinks.social,
          },
          additionalContent: {
            ...configuration.additional,
          },
        },
      };

      // configuration
      dispatch(trackingPageSlice.actions.handleTrackingPageValue(trackingPageValue));
      dispatch(trackingPageSlice.actions.handleTrackingPageOldValue(trackingPageValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrackingPage.data?.data]);

  const actionGroup = useMemo(() => {
    const hasChange = Object.entries(trackingPage.fieldsChange).length > 0;
    return [
      {
        content: 'Discard',
        onAction: () => {
          dispatch(
            trackingPageSlice.actions.handleTrackingPageValue({
              ...trackingPage.oldValue,
            }),
          );
          dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
          dispatch(trackingPageSlice.actions.handleChangeValidation([]));
        },
        primary: false,
        disabled: !hasChange,
      },
      {
        content: 'Save',
        disabled: !!trackingPage.validation.length || !hasChange || !shippingInfo.length || !progressBar.length,
        onAction: () => {
          updateTrackingPage(trackingPage.fieldsChange)
            .then((res: any) => {
              if (res?.data?.state === 1) {
                dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
                dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));

                // check show review table tab in sidebar
                if (trackingPage.fieldsChange?.reviewQuestions?.isActive !== accountState.isEnabledReview) {
                  dispatch(
                    planSlice.actions.handleChangePlan({
                      ...accountState,
                      isEnabledReview: trackingPage.fieldsChange?.reviewQuestions?.isActive,
                    }),
                  );
                }
                return;
              }
              setApiError(getErrorFromAPI(res));
            })
            .catch((err) => {});
        },
        primary: true,
        loading: isLoading,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingPage.fieldsChange, isLoading, trackingPage.validation]);

  const checkHidenControlBar = () => {
    const isHiden = tabsConfigPage.find((item) => item.value === tabSelected)?.hideControlBar;
    return (
      isUndefinedArray(Object.values(trackingPage.fieldsChange)) ||
      Object.entries(trackingPage.fieldsChange).length === 0 ||
      (tabSelected !== TrackingPageTabs.LookAndFeel && !!isHiden)
    );
  };

  return (
    <Layout
      title="Tracking Page"
      secondaryActions={[
        {
          content: 'Preview',
          icon: ViewMinor,
          url: LINK.CUSTOMER_SHOP(shop),
        },
      ]}
    >
      <ControlBar
        actionGroup={actionGroup}
        hidden={checkHidenControlBar()}
        changeFields={
          !isUndefinedArray(Object.values(trackingPage.fieldsChange)) || Object.entries(language.fieldsChange).length > 0
        }
      />
      <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
      <CustomPage
        tabsConfig={tabsConfigPage}
        selected={[isTabInTrackingPageTabs ? tabSelected : TrackingPageTabs.LookAndFeel]}
        onChange={handleTabSelected}
        selectedTab={tabSelected}
      />
    </Layout>
  );
};

export default TrackingPage;
