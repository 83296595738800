import { useAppBridge } from '@shopify/app-bridge-react';
import { Fullscreen } from '@shopify/app-bridge/actions';
import LocalBanner from '../LocalBanner';

const EmbedBanner = () => {
  const app = useAppBridge();
  const fullscreen = Fullscreen.create(app);

  const handleSwitchFullScreen = () => {
    fullscreen.dispatch(Fullscreen.Action.ENTER);
  };

  return <LocalBanner onFullScreen={handleSwitchFullScreen} />;
};

export default EmbedBanner;
