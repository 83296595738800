import { PATH } from '@/constants';
import { BlockStack, Button } from '@shopify/polaris';
import ShippingMethod from '../ShippingMethod';
import ShippingZone from '../ShippingZone';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { useEffect, useState } from 'react';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { useCreateTransitTimeMutation, useGetEDDSettingsQuery } from '@/redux/api/api.caller';
import { ICreatedTransitTime } from '@/types/edd';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { useNavigate } from 'react-router-dom';
import Layout from '@/components/Layout';
import { ErrorBanner, UpgradeBanner } from '@/components';
import { AccountPlan } from '@/constants/enum';

const TransitTimeEditor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const eddState = useSelector(eddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const eddSettingData = useGetEDDSettingsQuery();
  const [createTransitTime, createdTransitTimeData] = useCreateTransitTimeMutation();

  const handleCreateTransitTime = () => {
    const payload: ICreatedTransitTime = {
      shippingDestinationCountry: eddState.shippingZone.countriesCode,
      shippingMethod: eddState.shippingMethod.methodInfo,
    };
    createTransitTime(payload).then((data: any) => {
      if (data?.data?.state === 1) {
        setApiError('');
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        navigate(PATH.TRACKING_PAGE);
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  useEffect(() => {
    if (eddSettingData.data) {
      dispatch(
        trackingPageSlice.actions.handleChangeEDD({
          ...eddState,
          shippingMethod: {
            ...eddState.shippingMethod,
            methodInfo: [],
          },
          shippingZone: {
            ...eddState.shippingZone,
            countriesCode: [],
          },
          transitTimes: eddSettingData.data?.data?.transitTime || [],
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eddSettingData.data]);

  return (
    <Layout
      backAction={{ url: PATH.TRACKING_PAGE }}
      title="Estimated delivery date"
      primaryAction={{
        content: 'Save new rule',
        disabled: eddState.shippingZone.countriesCode.length === 0 || eddState.shippingMethod.methodInfo.length === 0,
        onAction: handleCreateTransitTime,
        loading: createdTransitTimeData.isLoading,
      }}
      secondaryActions={<Button url={PATH.TRACKING_PAGE}>Cancel</Button>}
    >
      <UpgradeBanner
        isOpen={[AccountPlan.Starter, AccountPlan.Basic].includes(plan.plan) && !plan.isOldPaidUser && !isActiveChristmasFeature}
        usedFeature={AccountPlan.Professional}
      />
      <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
      <BlockStack gap="400">
        <ShippingZone />
        <ShippingMethod />
      </BlockStack>
    </Layout>
  );
};

export default TransitTimeEditor;
