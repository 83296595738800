import { Button, Modal, SkeletonBodyText, Text } from '@shopify/polaris';
import { StyledTransitTimes } from './styled';
import { DeleteMinor, EditMinor, PlusMinor } from '@shopify/polaris-icons';
import { PATH, REST_OF_WORLD, allCountryData, continentCode } from '@/constants';
import { Fragment, useCallback, useState } from 'react';
import { countDuplicateValueInArr, isLockFeature } from '@/helpers';
import { useDeleteTransitTimeMutation, useGetEDDSettingsQuery } from '@/redux/api/api.caller';
import { useDispatch, useSelector } from 'react-redux';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { eddSelector } from '@/redux/features/trackingPage.slice';
import { CustomCard, ErrorBanner } from '@/components';
import { AccountPlan } from '@/constants/enum';

const TransitTimes = () => {
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const edd = useSelector(eddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;
  const [modal, setModal] = useState<{ isOpen: boolean; id?: number }>({ isOpen: false });

  const { isLoading } = useGetEDDSettingsQuery(undefined, { skip: isLockedFeature });
  const [deleteTransitTime, deletedTransitTimeData] = useDeleteTransitTimeMutation();

  const transformCourierName = useCallback((countriesName: Array<string>) => {
    if (countriesName.length === 1 && countriesName.toString() === REST_OF_WORLD) {
      return REST_OF_WORLD;
    }
    const courierInfo = countriesName.map((country) => allCountryData.find(({ code }) => code === country));
    const listContinentNo = Object.keys(
      countDuplicateValueInArr(
        countriesName.map((country) => allCountryData.find(({ code }) => code === country)?.continent || ''),
      ),
    );
    const listCountryByContinent = listContinentNo.map((continent) => {
      return {
        continent: continentCode[continent as keyof typeof continentCode],
        country: courierInfo.filter((courier) => courier?.continent === continent).map((item) => item?.name || ''),
      };
    });
    return listCountryByContinent.map(({ continent, country }, index) => (
      <Fragment key={continent}>
        <>
          {continent}
          <span className="detail-name">{`(${country.slice(0, 3).join(', ')}${country.length > 3 ? ',..' : ''})`}</span>
        </>
        {listCountryByContinent.length - 1 !== index && <span>{`, `}</span>}
      </Fragment>
    ));
  }, []);

  const handleCloseModal = () => {
    setApiError('');
    setModal((prev) => ({ ...prev, isOpen: false }));
  };
  const handleClickDeleteIcon = (id: number) => setModal((prev) => ({ ...prev, isOpen: true, id }));

  const handleDeleteTransitTime = () => {
    deleteTransitTime({ id: modal.id || 0 }).then((data: any) => {
      if (data?.data?.state === 1) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        handleCloseModal();
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  return (
    <StyledTransitTimes>
      <CustomCard
        className="mt-16"
        title="Transit times"
        actionGroup={[
          {
            content: 'Add new rule',
            variant: 'plain',
            url: PATH.TRANSIT_TIME,
            icon: PlusMinor,
            disabled: isLockedFeature,
          },
        ]}
      >
        {isLoading ? (
          <SkeletonBodyText lines={10} />
        ) : (
          edd?.transitTimes?.length > 0 &&
          edd.transitTimes.map((item) => (
            <div className="transit-times-item" key={item.id}>
              <div className="line-tile">
                <Text variant="headingSm" as="h6">
                  {transformCourierName(item.shippingDestinationCountry)}
                </Text>
                <div className="group-btn">
                  <Button icon={EditMinor} url={`${PATH.TRANSIT_TIME}/${item.id}`} disabled={isLockedFeature} />
                  <Button
                    icon={DeleteMinor}
                    disabled={item.shippingDestinationCountry.includes('Rest of world') || isLockedFeature}
                    onClick={() => handleClickDeleteIcon(item.id)}
                  />
                </div>
              </div>
              {item.shippingMethod.map((ele) => (
                <div className="line-description" key={ele.courier.toString()}>
                  <p className="left">{`${ele.courier.slice(0, 5).join(', ')}${ele.courier.length > 5 ? ',...' : ''}`}</p>
                  <p className="right"> {`${ele.first} - ${ele.second} business days`}</p>
                </div>
              ))}
            </div>
          ))
        )}
      </CustomCard>

      <Modal
        open={modal.isOpen}
        onClose={handleCloseModal}
        title="Delete Transit time"
        primaryAction={{
          content: 'Delete',
          onAction: handleDeleteTransitTime,
          loading: deletedTransitTimeData.isLoading,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Discard',
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
          Are you sure you want to delete?
        </Modal.Section>
      </Modal>
    </StyledTransitTimes>
  );
};

export default TransitTimes;
