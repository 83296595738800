import Layout from '@/components/Layout';
import {
  analyticsSlice,
  analyticsTabSelectedSelector,
  datePickerSelector,
  titleDatePickerSelector,
} from '@/redux/features/analytics.slice';
import { useDispatch, useSelector } from 'react-redux';
import { tabsConfig } from './config';
import { CustomPage, UpgradeBanner } from '@/components';
import DatePicker from '@/components/DatePicker';
import { Button, Modal, TextField } from '@shopify/polaris';
import { AnalyticsTabs } from '@/constants';
import { useState } from 'react';
import { useIntegrateGoogleAnalyticsMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { StyledAnalytics } from './styled';
import { isLockFeature } from '@/helpers';
import { AccountPlan } from '@/constants/enum';

const Analytics = () => {
  const [state, setState] = useState({
    value: '',
    isOpenModal: false,
  });
  const isLock = isLockFeature([AccountPlan.Starter]);
  const dispatch = useDispatch();
  const tabSelected = useSelector(analyticsTabSelectedSelector);
  const { start, end } = useSelector(datePickerSelector);
  const titleDatePicker = useSelector(titleDatePickerSelector);
  const [isViewDemo, setViewDemo] = useState<boolean>(!isLock);
  const [apiError, setApiError] = useState<string>('');

  const [integrateGoogleAnalytics, { isLoading }] = useIntegrateGoogleAnalyticsMutation();

  const handleChangeTab = (value: Array<AnalyticsTabs>) => {
    dispatch(analyticsSlice.actions.handleChangeTab(value[0]));
  };

  const handleChangeDatePicker = (start: Date, end: Date) => {
    dispatch(analyticsSlice.actions.handleChangeDatePicker({ start, end }));
  };
  const handleChangeTitleBtn = (titleBtn: string) => {
    dispatch(analyticsSlice.actions.handleChangeTitleBtn(titleBtn));
  };

  const handleCloseModal = () => {
    setApiError('');
    setState({ value: '', isOpenModal: false });
  };

  const handleOpenModal = () => {
    setState({ ...state, isOpenModal: true });
  };

  const handleChange = (value: string) => {
    setApiError('');
    setState({ ...state, value });
  };

  const handleIntergrateGA = () => {
    integrateGoogleAnalytics({ gaTrackingId: state.value }).then((data: any) => {
      if (data?.data?.state === 1) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        handleCloseModal();
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  return (
    <StyledAnalytics>
      <Layout
        fullWidth
        title="Analytics"
        primaryAction={
          tabSelected === AnalyticsTabs.TrackingPageReport &&
          isViewDemo && (
            <Button variant="primary" onClick={handleOpenModal} disabled={isLock}>
              Integrate with Google Analytics
            </Button>
          )
        }
        secondaryActions={
          isViewDemo && (
            <DatePicker
              start={start}
              end={end}
              titleBtn={titleDatePicker}
              setTitleBtn={handleChangeTitleBtn}
              onSave={handleChangeDatePicker}
              disabled={isLock}
            />
          )
        }
      >
        {isLock && (
          <UpgradeBanner
            usedFeature={AccountPlan.Basic}
            secondaryAction={
              isViewDemo
                ? undefined
                : {
                    content: 'View Demo',
                    onAction: () => setViewDemo(true),
                  }
            }
          />
        )}
        {isViewDemo && (
          <CustomPage
            navResponsiveWidth="80rem"
            tabsConfig={tabsConfig}
            selected={[tabSelected]}
            onChange={handleChangeTab}
            isShowLockFeature={false}
            selectedTab={tabSelected}
          />
        )}
        <Modal
          onClose={handleCloseModal}
          open={state.isOpenModal}
          title="Google Analytics ID"
          primaryAction={{ content: 'Save', onAction: handleIntergrateGA, disabled: !state.value, loading: isLoading }}
          secondaryActions={[{ content: 'Cancel', onAction: handleCloseModal }]}
        >
          <div style={{ padding: '1rem' }}>
            <TextField
              label="Google Analytics ID"
              value={state.value}
              onChange={handleChange}
              autoComplete="off"
              error={apiError}
            />
          </div>
        </Modal>
      </Layout>
    </StyledAnalytics>
  );
};

export default Analytics;
